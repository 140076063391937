import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { GetFileRequest, GetFileResponse } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_pb';
import { Submission } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_types_pb';
import { EduService } from '@buf/sphere_edu.connectrpc_es/edu/v1/edu_connect';
import * as React from 'react';
import { createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import {
  Alert,
  AlertActionCloseButton,
  AlertActionLink,
  AlertGroup,
  AlertVariant,
  Button,
} from '@patternfly/react-core';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

type SubmissionFilesDownloadProps = {
  classId: string;
  assignmentId: string;
  onClick: () => void;

  /**
   *
   * @param progress: number from [0, 100] that represents percentage complete
   * @returns
   */
  downloadProgress: (progress: number) => void;
};

type FileDataProps = {
  userId: string;
  fileName: string;
  data: GetFileResponse[];
};

type FileProps = {
  userId: string;
  file: File;
};

type LocalAlert = {
  title: string;
  variant: AlertVariant;
  actionLinks?: React.ReactNode;
  key?: number;
  children?: React.ReactNode;
  timeout?: number;
};

type AddLocalAlert = (a: React.PropsWithChildren<LocalAlert>) => void;

// Can be set to whatever, but *HAS TO BE* consistent across chunks. Set to 512 kB to match uploading a file.
const CHUNK_SIZE = 512 * 1024; // 512 KB

const SubmissionFilesDownload: React.FunctionComponent<SubmissionFilesDownloadProps> = ({
  classId,
  assignmentId,
  downloadProgress,
  onClick,
}) => {
  const conf = React.useContext(GeneralSettingsContext);
  const controller = new AbortController();
  const { signal } = controller;

  const transport = createConnectTransport({
    baseUrl: `${conf.eduApi}`,
    credentials: 'include',
  });
  const client = createPromiseClient(EduService, transport);

  const [alerts, setAlerts] = React.useState<React.PropsWithChildren<LocalAlert[]>>([]);

  const [submissions, setSubmissions] = React.useState<Submission[]>([]);
  const [progress, setProgress] = React.useState<number>(0);

  React.useMemo(async () => {
    client
      .getSubmissions({
        classId: classId,
        assignmentId: assignmentId,
      })
      .then((res) => {
        // This is a spelling error in the API, it should be res.submissions, but I am too lazy to fix 😕
        setSubmissions(res.submission);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  // Update progress in callback fn whenever progress changes
  React.useEffect(() => {
    // Don't call callback fn if progress is reset
    if (progress == 0) return;

    downloadProgress(progress);
  }, [progress]);

  const handleClick = async () => {
    // Check if there are any submissions, if not, send an alert and don't download anything
    if (submissions.length == 0) {
      addAlert({
        title: 'There are no files to download for this assignment',
        variant: AlertVariant.info,
        timeout: 5000,
      });
    } else {
      // Only call onClick when there are files to download
      onClick();

      // Get all the file data for each submission and aggregate it into this array
      const fileData: FileDataProps[] = await getFileData();

      // Stitch each of the files together and put into array
      const fileArray: FileProps[] = stitchFiles(fileData);

      const files = fileArray.map((f) => f.file);

      // Put files into a zip and download it to the user's computer
      await generateZipFile(files);
    }

    // Reset progress bar and cancel download
    setProgress(0);
  };

  const generateZipFile = async (files: File[]) => {
    const zip = new JSZip();

    // Create folder to act as root of zip file so that it doesn't unzip into a million files
    const folder = zip.folder(assignmentId);

    if (folder) {
      files.map((f) => {
        folder.file(f.name, f);
      });
    }

    // Alert user that download is complete
    addAlert({
      title: 'Download Complete',
      variant: AlertVariant.info,
      timeout: 15000,
    });

    await zip.generateAsync({ type: 'blob' }).then((zipFile) => {
      saveAs(zipFile, `${assignmentId}.zip`);
    });
  };

  const stitchFiles = (fileData: FileDataProps[]): FileProps[] => {
    let files: FileProps[] = [];

    fileData.map((fd) => {
      const fileStats = fd.data[0];

      // Assemble data into array in order.
      let data = new Array<Blob>(fileData.length);
      fd.data.map((gfr) => {
        data[gfr.chunkNumber] = new Blob([gfr.data]);
      });

      files.push({
        userId: fd.userId,
        file: new File(data, `${fileStats.fileId}-${fd.fileName}`, { type: fileStats.fileType }),
      });
    });

    return files;
  };

  const getFileData = async (): Promise<FileDataProps[]> => {
    let fileData: FileDataProps[] = [];

    // Get all submissions for the stated assignment
    await Promise.all(
      submissions.map(async (s) => {
        // If an error occurs, then stop download for remainder of file
        let abort = false;

        // Set as a default, will change based on what the server responds with on the first request
        let totalChunks = 1;
        let chunkNumber = 0;

        let userFileData: FileDataProps = {
          userId: s.userId,
          fileName: s.filename,
          data: new Array<GetFileResponse>(),
        };

        // Get each file chunk by asking for each successive chunk until the totalChunk limit is hit and add it to the array
        while (!abort && chunkNumber < totalChunks) {
          await client
            .getFile(
              new GetFileRequest({
                fileId: s.userId,
                bucketId: s.assignmentId,
                chunkNumber: chunkNumber,
                maxChunkSize: CHUNK_SIZE,
              })
            )
            .then((res) => {
              // Change totalChunks from 1 to the amount the backend says
              totalChunks = res.totalChunks;
              userFileData.data.push(res);

              // Update progress every chunk received
              setProgress((progress) => {
                // Each file gets an equal share of the progress bar
                const percentPerFile = 100 / submissions.length;

                // Each chunk gets an equal share of the file's share of the progress bar
                const percentPerChunk = percentPerFile / totalChunks;

                // Add one chunks worth of progress
                return progress + percentPerChunk;
              });
            })
            .catch((err) => {
              abort = true;

              // TODO: Raise alert saying download failed
              console.error(`File ${s.userId} failed`, err);
              addAlert({
                title: `Error getting file for ${s.userId}`,
                variant: AlertVariant.danger,
                timeout: 10000,
              });

              // Set remaining file progress to 100% of that file
              setProgress((progress) => {
                // Each file gets an equal share of the progress bar
                const percentPerFile = 100 / submissions.length;

                // Each chunk gets an equal share of the file's share of the progress bar
                const percentPerChunk = percentPerFile / totalChunks;

                // Add one chunks worth of progress
                return progress + percentPerChunk * (totalChunks - chunkNumber);
              });
            });
          chunkNumber++;
        }

        // Only add file if it was a success
        if (!abort) {
          fileData.push(userFileData);
        }
      })
    );

    return fileData;
  };

  const addAlert = (a: LocalAlert) => {
    setAlerts((prev) => [...prev, { ...a, key: prev.length }]);
  };

  const removeAlert = (key: number | undefined) => {
    if (key !== undefined) {
      setAlerts(alerts.filter((k) => k.key !== key));
    }
  };

  const notifications = (
    <AlertGroup isToast isLiveRegion>
      {alerts.map((a) => {
        return (
          <Alert
            title={a.title}
            variant={a.variant}
            key={a.key}
            actionClose={
              <AlertActionCloseButton title={a.title} variantLabel={a.title} onClose={() => removeAlert(a.key)} />
            }
            actionLinks={a.actionLinks}
            timeout={a.timeout ?? 8000}
          >
            {a.children}
          </Alert>
        );
      })}
    </AlertGroup>
  );

  return (
    <React.Fragment>
      {alerts.length > 0 && notifications}
      <Button title="Download Submissions" onClick={handleClick}>
        Download Submissions
      </Button>
    </React.Fragment>
  );
};

export { SubmissionFilesDownload };
