import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { AuthContext } from '@app/lib/AuthProvider';
import { GetClassResponse } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_pb';
import { Class, UserType, User } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_types_pb';
import { GenericError } from '@ory/kratos-client';
import {
  Alert,
  AlertActionLink,
  AlertGroup,
  AlertVariant,
  ActionGroup,
  Button,
  Form,
  FormGroup,
  List,
  ListItem,
  PageSection,
  Radio,
  TextArea,
  Title,
  Breadcrumb,
  BreadcrumbItem,
  Bullseye,
  Spinner,
  AlertActionCloseButton,
} from '@patternfly/react-core';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  GetUserResponse,
  MembershipType,
  RequestOrganizationMembershipRequest,
  ConfirmOrganizationMembershipRequest,
  UpdateOrganizationMemberRequest,
  User as PortalUser,
  Member,
  Member_Role,
  GetOrganizationResponse,
  GetUserRequest,
} from '@mergetb/api/portal/v1/workspace_types';
import { RegisterRequest } from '@mergetb/api/portal/v1/identity_types';
import { useFetch } from 'use-http';
import { EduService } from '@buf/sphere_edu.connectrpc_es/edu/v1/edu_connect';
import { createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import generator from 'generate-password-ts';
import { Port } from '@mergetb/api/mergetb/xir/v0.3/core';

type AddUserProps = {
  classId: string;
};

type LocalAlert = {
  title: string;
  variant: AlertVariant;
  actionLinks?: React.ReactNode;
  key?: number;
};
type AddLocalAlert = (a: React.PropsWithChildren<LocalAlert>) => void;

const AddUsers: React.FunctionComponent<AddUserProps> = () => {
  const { classId } = useParams<AddUserProps>();
  const conf = React.useContext(GeneralSettingsContext);
  const { user: caller } = React.useContext(AuthContext);
  const username = caller?.username;
  const history = useHistory();

  const [alerts, setAlerts] = React.useState<React.PropsWithChildren<LocalAlert[]>>([]);

  const options = { credentials: 'include', cache: 'no-cache' };
  const { get: getOrg, response: respOrg } = useFetch(`${conf.api}/organization/${classId}`, options);

  const [emails, setEmails] = React.useState('');
  const [response, setResponse] = React.useState<Response>();
  const [classroom, setClassroom] = React.useState<Class>();
  const [activeRadio, setActiveRadio] = React.useState<number>(0);
  const [prof, setProf] = React.useState<PortalUser>();
  const [profName, setProfName] = React.useState<string>();
  const [usersAdded, setUsersAdded] = React.useState<number>(0);
  const [usersToAdd, setUsersToAdd] = React.useState<number>(0);

  const client = React.useMemo(() => {
    const transport = createConnectTransport({
      baseUrl: `${conf.eduApi}`,
      credentials: 'include',
    });
    return createPromiseClient(EduService, transport);
  }, [conf.eduApi]);

  const loadOrg = React.useCallback(async () => {
    const data = await getOrg();
    if (respOrg.ok && Object.hasOwnProperty.call(data, 'organization')) {
      const org = GetOrganizationResponse.fromJSON(data).organization;

      if (org?.members) {
        for (const name of Object.keys(org.members)) {
          if (org.members[name].role == Member_Role.Creator) {
            setProfName(name);
            break;
          }
        }
      }
    }
  }, [respOrg, getOrg]);

  const loadProf = React.useCallback(async () => {
    if (profName) {
      const resp = await fetch(`${conf.api}/user/${profName}`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (resp.ok) {
        const data = await resp.json();
        setProf(GetUserResponse.fromJSON(data).user);
      } else {
        throw new Error(resp.statusText);
      }
    }
  }, [conf.api, profName]);

  React.useEffect(() => {
    loadOrg();
  }, [loadOrg]);

  React.useEffect(() => {
    loadProf().catch((e) => console.log('error fetching org creator account', e));
  }, [loadProf, profName]);

  React.useMemo(async () => {
    const data = await fetch(`${conf.eduApi}/edu.v1.EduService/GetClass`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        classId: classId,
      }),
      credentials: 'include',
      cache: 'no-cache',
    }).then((response) => {
      setResponse(response);
      return response.json();
    });

    if (Object.hasOwnProperty.call(data, 'class')) {
      setClassroom(GetClassResponse.fromJson(data).class);
    }
  }, [classId, conf.eduApi]);

  if (classroom == undefined) {
    if (response && response.ok) {
      return (
        <React.Fragment>
          <PageSection>
            <Alert variant="danger" title="Not Found Error">
              <pre>This class does not exist</pre>
            </Alert>
          </PageSection>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Bullseye>
            <Spinner size="xl" />
          </Bullseye>
        </React.Fragment>
      );
    }
  } else if (
    username &&
    (classroom.users[username] == undefined ||
      classroom.users[username] == UserType.STUDENT ||
      classroom.users[username] == UserType.UNSPECIFIED)
  ) {
    return (
      <React.Fragment>
        <PageSection>
          <Alert variant="danger" title="Permission Error">
            <pre>You do not have permission to access this content</pre>
          </Alert>
        </PageSection>
      </React.Fragment>
    );
  }

  const handleEmailsChange = (value: string, _event) => {
    setEmails(value);
  };

  const removeAlert = (key: number | undefined) => {
    if (key !== undefined) {
      setAlerts(alerts.filter((k) => k.key !== key));
    }
  };

  const addAlert = (a: LocalAlert) => {
    setAlerts((prev) => [...prev, { ...a, key: prev.length }]);
  };

  const handleSubmit = async () => {
    const entries: string[] = emails.split('\n');

    if (entries.length === 0) {
      addAlert({
        title: 'You must supply at least one valid email address',
        variant: AlertVariant.warning,
      });
      return;
    }

    for (let i = 0; i < entries.length; i++) {
      entries[i] = entries[i].trim();
    }

    // validate input.
    // see https://emailregex.com/
    const emailRegEx = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    let invalid = false;
    const validEmail = entries.filter((m) => {
      if (!m) {
        return false;
      }
      if (emailRegEx.test(m) !== true) {
        addAlert({
          title: `Bad Email Format: ${m}`,
          variant: AlertVariant.danger,
        });
        invalid = true;
        return false;
      }
      return true;
    });

    if (invalid) {
      return false;
    }

    setUsersToAdd(validEmail.length);

    const created: string[] = [];
    for (let i = 0; i < validEmail.length; i++) {
      const u = await getUsername(classId, conf);
      if (u === '') {
        continue;
      }

      const success = await createStudent(
        u,
        validEmail[i],
        prof,
        caller,
        classroom,
        client,
        conf,
        addAlert,
        activeRadio === 0
      );
      console.log(`create student ${validEmail[i]} success: ${success}`);
      if (!success) {
        addAlert({
          title: `Error creating user ${u} (${validEmail[i]})`,
          variant: AlertVariant.danger,
        });
        continue;
      }
      created.push(`${u} (${validEmail[i]})`);
      setUsersAdded((usersAdded) => usersAdded + 1);
    }

    setUsersToAdd(0);

    if (created.length > 0) {
      addAlert({
        title: 'Users Created',
        variant: AlertVariant.success,
        actionLinks: (
          <>
            <AlertActionLink onClick={() => history.push(`/edu/class/${classId}/users`)}>Manage Users</AlertActionLink>
          </>
        ),
        children: (
          <List isPlain>
            {created.map((u, i) => (
              <ListItem key={i}>{u}</ListItem>
            ))}
          </List>
        ),
      });
    }
  };

  const notifications = (
    <AlertGroup isToast isLiveRegion>
      {usersToAdd > 0 && <Alert title={`Created ${usersAdded} of ${usersToAdd} Users`} />}
      {alerts.map((a) => {
        return (
          <Alert
            title={a.title}
            variant={a.variant}
            key={a.key}
            actionClose={
              <AlertActionCloseButton title={a.title} variantLabel={a.title} onClose={() => removeAlert(a.key)} />
            }
            actionLinks={a.actionLinks}
          >
            {a.children}
          </Alert>
        );
      })}
    </AlertGroup>
  );

  return (
    <React.Fragment>
      {notifications}
      <PageSection>
        <Breadcrumb>
          <BreadcrumbItem to="/edu/teaching">Manage Classes</BreadcrumbItem>
          <BreadcrumbItem to={`/edu/class/${classId}`}>Class {classId}</BreadcrumbItem>
          <BreadcrumbItem isActive>Add Users to {classId}</BreadcrumbItem>
        </Breadcrumb>
        <Title headingLevel="h1">Add users to {classId}</Title>
        <Form>
          <FormGroup label="Emails" isRequired fieldId="simple-form-email-01" helperText="One email per line">
            <TextArea
              isRequired
              autoResize
              id="simple-form-email-01"
              name="simple-form-email-01"
              value={emails}
              onChange={handleEmailsChange}
            />
          </FormGroup>
          <FormGroup isRequired role="radiogroup" isInline fieldId="basic-form-radio-group" label="Students or TAs?">
            <Radio
              name="basic-inline-radio"
              label="Students"
              id="basic-inline-radio-0"
              isChecked={activeRadio === 0}
              onChange={() => setActiveRadio(0)}
            />
            <Radio
              name="basic-inline-radio"
              label="TAs"
              id="basic-inline-radio-1"
              isChecked={activeRadio === 1}
              onChange={() => setActiveRadio(1)}
            />
          </FormGroup>
          <ActionGroup>
            <Button variant="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </ActionGroup>
        </Form>
      </PageSection>
    </React.Fragment>
  );
};

// const registerPortalUser = (classId: string, prof: PortalUser, student: User): boolean => {
//   return false;
// };

const studentName = (base: string): string => {
  const chars = 'abcdefghijklmnopqrstuvwxyz';

  for (let i = 0; i < 4; i++) {
    base += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return base;
};

const getUsername = async (classId: string, conf): Promise<string> => {
  let username = '';
  let tries = 5;

  do {
    tries--;
    username = studentName(classId);

    // connect to portal to see if user exists.
    const resp = await fetch(`${conf.api}/user/${username}`, {
      credentials: 'include',
      cache: 'no-cache',
    }).catch((err) => console.log('get user error', err));

    if (resp && resp.status === 404) {
      tries = 0;
    } else {
      username = '';
      tries--;
    }
  } while (tries > 0);

  if (username) {
    console.log('found unused username', username);
  } else {
    console.log('no username found');
  }

  return username;
};

const createStudent = async (
  username: string,
  email: string,
  prof: PortalUser | undefined,
  caller: PortalUser | undefined,
  classroom: Class,
  client,
  conf,
  addAlert: AddLocalAlert,
  isStudent: boolean
): Promise<boolean> => {
  if (prof === undefined) {
    return false;
  }

  const classId = classroom.classId;
  const classes: { [key: string]: UserType } = {};
  classes[classId] = isStudent ? UserType.STUDENT : UserType.TA;

  const password = generator.generate({
    length: 10,
    numbers: true,
  });

  // create portal user.
  const success = await createPortalUser(username, password, email, classId, prof, caller, conf, addAlert, isStudent);
  if (!success) {
    return false;
  }

  // Create the new EDU user
  await client
    .createUser({
      userId: username,
      fullName: username.split(classId)[1] + ' ' + classId, // use suffix as name
      classes: classes,
      email: email,
      password: password,
    })
    .catch((e) => {
      console.log('caught create edu user error', e);
      addAlert({
        variant: AlertVariant.danger,
        title: 'Error creating EDU user',
        children: <pre>{e.message}</pre>,
      });
      return false;
    });

  // add them to the class
  const users: { [key: string]: UserType } = {};
  users[username] = isStudent ? UserType.STUDENT : UserType.TA;

  console.log(isStudent ? 'UserType.STUDENT' : 'UserType.TA');

  await client
    .addUsersToClass({
      classId: classId,
      users: users,
    })
    .catch((e) => {
      console.log('add user to class error', e);
      return false;
    });

  return true;
};

const createPortalUser = async (
  u: string,
  pw: string,
  mail: string,
  classId: string,
  prof: PortalUser,
  caller: PortalUser,
  conf,
  addAlert: AddLocalAlert,
  isStudent: boolean
): Promise<boolean> => {
  const regReq = RegisterRequest.fromJSON({
    username: u,
    password: pw,
    email: mail,
    institution: prof.institution ? prof.institution : 'unknown',
    category: 'student',
    country: prof.country ? prof.country : 'unknown',
    usstate: prof.usstate ? prof.usstate : '',
    name: u.split(classId)[1] + ' ' + classId,
  });

  // register the user
  let resp = await fetch(`${conf.api}/register`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(regReq),
  }).catch((e) => {
    console.log('registering portal user caught error', e);
  });

  if (!resp) {
    return false;
  }

  if (!resp.ok) {
    console.log('registering portal user response not ok');
    const body = await resp.json();

    if (isKratosRegisterError(body)) {
      const node = kratosRegsisterError(body);
      addAlert({
        title: `Error creating portal user ${u} (${mail})`,
        variant: AlertVariant.danger,
        children: node,
      });
    }
    return false;
  }

  // add the user to the class organization TODO: this is duplicate code (in Organizations.tsx) export and use once.
  const orgMemReq = RequestOrganizationMembershipRequest.fromJSON({
    organization: classId,
    id: u,
    kind: MembershipType.UserMember,
    // member default is Member, Pending.
  });

  resp = await fetch(`${conf.api}/organization/${classId}/member/${u}`, {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify(orgMemReq),
  }).catch((e) => {
    // TODO: unregister the user!
    console.log('user org member request: caught error', e);
  });

  if (!resp) {
    console.log('request org membership response void');
    return false;
  }
  if (!resp.ok) {
    console.log('request org membership response not ok');
    return false;
  }
  console.log('request org membership response ok');

  // Note: if caller is admin we do not need to confirm the request.
  if (!caller.admin) {
    const confReq = ConfirmOrganizationMembershipRequest.fromJSON({ organization: classId, id: u });
    resp = await fetch(`${conf.api}/organization/${classId}/member/${u}/confirm`, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(confReq),
    }).catch((e) => {
      console.log('confirm user org member: caught error', e);
    });

    if (!resp) {
      console.log('confirm user org member response void');
      return false;
    }

    if (!resp.ok) {
      console.log('confirm user org member: response not ok');
      const body = await resp.json();
      if (body) {
        addAlert({
          title: 'Error Confirming User Membership in Class',
          variant: AlertVariant.danger,
          children: <pre>{body.message}</pre>,
        });
      }
      return false;
    }

    console.log('confirm user org member: response ok');
  }

  // If a TA then set as org maintainer.
  if (!isStudent) {
    const member = Member.fromJSON({ role: Member_Role.Maintainer });
    const req = UpdateOrganizationMemberRequest.fromJSON({
      organization: classId,
      username: u,
      member: member,
    });

    const resp = await fetch(`${conf.api}/organization/${classId}/member/${u}`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(req),
    }).catch((e) => {
      console.log('error in update org member', e);
    });

    if (!resp) {
      console.log('update org member response void');
      return false;
    }

    if (!resp.ok) {
      console.log('update org member response not ok');
      const body = await resp.json();
      if (body) {
        addAlert({
          title: `Error Setting User to Maintainer ${u} (${mail})`,
          variant: AlertVariant.danger,
          children: <pre>{body.message}</pre>,
        });
      }
      return false;
    }

    console.log('Set', u, 'to Maintainer');
  }

  return true;
};

const isKratosRegisterError = (j: any): j is GenericError => {
  if (j === undefined) return false;
  return 'code' in j && 'message' in j && 'details' in j; // TODO figure this out... && 'fieldViolations' in j.details;
};

// This is not great. TODO: figure out types from GRPC responses.
const kratosRegsisterError = (e: GenericError): React.ReactNode => {
  console.log('kratos error', e);
  return (
    <React.Fragment>
      <List>
        {e.details?.map((d, i) => {
          let title = '';
          let detail = '';
          for (let fvi = 0; fvi < d.fieldViolations.length; fvi++) {
            if (d.fieldViolations[fvi].field == 'Title') {
              title = d.fieldViolations[fvi].description;
            }
            if (d.fieldViolations[fvi].field == 'Detail') {
              detail = d.fieldViolations[fvi].description;
            }
          }
          if (title && detail) {
            return (
              <ListItem key={i}>
                {title}: {detail}
              </ListItem>
            );
          }
        })}
      </List>
    </React.Fragment>
  );
};

export { AddUsers };
