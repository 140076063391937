import {
  ActionGroup,
  Button,
  Form,
  FormGroup,
  PageSection,
  Radio,
  Title,
  Breadcrumb,
  BreadcrumbItem,
  TextInput,
  Alert,
  Bullseye,
  Spinner,
  FormSelect,
  FormSelectOption,
  DatePicker,
  Modal,
  ModalVariant,
  FormAlert,
  Split,
  SplitItem,
  ProgressMeasureLocation,
  Progress,
} from '@patternfly/react-core';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Class, UserType, Material, Assignment } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_types_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { AuthContext } from '@app/lib/AuthProvider';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { ExclamationCircleIcon } from '@patternfly/react-icons';
import { EduService } from '@buf/sphere_edu.connectrpc_es/edu/v1/edu_connect';
import { createPromiseClient, ConnectError } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { TimePicker } from '@app/lib/TimePicker';
import { SubmissionFilesDownload } from '@app/lib/SubmissionFilesDownload';

type AssignmentProps = {
  classId: string;
  assignmentId: string;
};

type ValidatedProps = 'default' | 'success' | 'warning' | 'error';

const AssignmentPage: React.FunctionComponent<AssignmentProps> = () => {
  const { classId, assignmentId } = useParams<AssignmentProps>();
  const { user } = React.useContext(AuthContext);

  const username = user?.username;
  const conf = React.useContext(GeneralSettingsContext);
  const transport = createConnectTransport({
    baseUrl: `${conf.eduApi}`,
    credentials: 'include',
  });
  const client = createPromiseClient(EduService, transport);
  const history = useHistory();

  const [response, setResponse] = React.useState<boolean>();

  const [assignment, setAssignment] = React.useState<Assignment>();
  const [dueDate, setDueDate] = React.useState<Date>(new Date());
  const [dueDateString, setDueDateString] = React.useState<string>('');
  const [dueTime] = React.useState<number>(86399); // Hardcoded to 11:59:59 PM
  // const [dueTimeString, setDueTimeString] = React.useState<string>('');
  const [selected, setSelected] = React.useState('success');
  const [classroom, setClassroom] = React.useState<Class>();
  const [materials, setMaterials] = React.useState<Map<string, Material>>(new Map());
  const [visibility, setVisibility] = React.useState<string>('');
  const [releaseDate, setReleaseDate] = React.useState<Date>(new Date());
  const [releaseDateString, setReleaseDateString] = React.useState<string>('');
  const [releaseTime] = React.useState<number>(0); // Hardcoded to 12:00:00 AM
  // const [releaseTimeString, setReleaseTimeString] = React.useState<string>('');
  const [dropdownItems, setDropdownItems] = React.useState<JSX.Element[]>([]);
  const [assignmentName, setAssignmentName] = React.useState<string>('');

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [formValidated, setFormValidated] = React.useState<ValidatedProps>('default');
  const [nameValidated, setNameValidated] = React.useState<ValidatedProps>('success');
  const [materialValidated, setMaterialValidated] = React.useState<ValidatedProps>('success');
  const [dueDateValidated, setDueDateValidated] = React.useState<ValidatedProps>('success');
  const [releaseDateValidated, setReleaseDateValidated] = React.useState<ValidatedProps>('success');
  const [visibilityValidated, setVisibilityValidated] = React.useState<ValidatedProps>('success');
  const [dueDateHelperText, setDueDateHelperText] = React.useState<string>('');
  const [releaseDateHelperText, setReleaseDateHelperText] = React.useState<string>('');
  const [error, setError] = React.useState<ConnectError>(new ConnectError(''));
  const [downloadProgress, setDownloadProgress] = React.useState<number>(0);

  const timestampToDateString = (date: Date | undefined) => {
    let dateString = '';
    if (date != undefined) {
      dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }
    return dateString;
  };

  // const timestampToTimeString = (date: Date | undefined) => {
  //   if (date == undefined) {
  //     return 'invalid'
  //   }

  //   // console.log(date.valueOf() / 1000, date.getTimezoneOffset() / 60)

  //   const time = Math.floor((date.valueOf() - (date.getTimezoneOffset() * 60 * 1000 * 2)) / 1000) % 86400;

  //   let period = '';
  //   let hour = Math.floor(time / 3600);

  //   if (hour == 0) {
  //     period = 'AM';
  //     hour = 12;
  //   } else if (hour > 12) {
  //     hour -= 12;
  //     period = 'PM';
  //   } else {
  //     period = 'AM';
  //   }

  //   const minute = Math.floor((time % 3600) / 60);

  //   // console.log(hour, minute, period)

  //   if (minute < 10) {
  //     return `${hour}:0${minute} ${period}`;
  //   }

  //   return `${hour}:${minute} ${period}`;
  // };

  React.useMemo(async () => {
    await client
      .getClass({
        classId: classId,
      })
      .then(async (response) => {
        setResponse(true);
        setClassroom(response.class);

        await client
          .getMaterials({
            classId: classId,
          })
          .then((response) => {
            response.materials.map((m) => {
              materials.set(m.materialId, m);
              setMaterials(materials);
            });

            dropdownItems.push(
              <FormSelectOption key={'default-option'} value={'default'} label={'Select Material to Assign'} />
            );
            setDropdownItems(dropdownItems);

            materials.forEach((m, k) => {
              console.log(k);
              dropdownItems.push(<FormSelectOption key={k} value={k} label={m.displayName} />);
              setDropdownItems(dropdownItems);
            });
          })
          .catch((error) => error);
      })
      .catch((error) => error);

    await client
      .getAssignment({
        assignmentId: assignmentId,
        classId: classId,
      })
      .then((response) => {
        const a = response.assignment;
        if (a) {
          setAssignment(a);
          setAssignmentName(a.displayName);
          setSelected(a.material?.materialId || '');
          setVisibility(a.isVisibleToAll ? 'all' : 'prof');
          setDueDate(a.dueDate!.toDate());
          setReleaseDate(a.releaseDate!.toDate());
          setDueDateString(timestampToDateString(a.dueDate!.toDate()));
          setReleaseDateString(timestampToDateString(a.releaseDate!.toDate()));
          // setDueTimeString(timestampToTimeString(a.dueDate?.toDate()))
          // setReleaseTimeString(timestampToTimeString(a.releaseDate?.toDate()))
        }
      })
      .catch((error) => error);
  }, [classId, assignmentId]);

  if (classroom == undefined) {
    if (response) {
      return (
        <React.Fragment>
          <PageSection>
            <Alert variant="danger" title="Not Found Error">
              <pre>This class does not exist</pre>
            </Alert>
          </PageSection>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Bullseye>
            <Spinner size="xl" />
          </Bullseye>
        </React.Fragment>
      );
    }
  } else if (
    username &&
    (classroom.users[username] == undefined ||
      classroom.users[username] == UserType.STUDENT ||
      classroom.users[username] == UserType.UNSPECIFIED)
  ) {
    return (
      <React.Fragment>
        <PageSection>
          <Alert variant="danger" title="Permission Error">
            <pre>You do not have permission to access this content</pre>
          </Alert>
        </PageSection>
      </React.Fragment>
    );
  }

  const handleAssignmentName = (value: string) => {
    // Clear banner if present
    if (formValidated != 'default') setFormValidated('default');

    setAssignmentName(value);
    value = value.trim();
    if (value == '') {
      setNameValidated('error');
    } else {
      setNameValidated('success');
    }
  };

  const onSelect = (value: string) => {
    // Clear banner if present
    if (formValidated != 'default') setFormValidated('default');

    setSelected(value);
    if (value == 'default') {
      setMaterialValidated('error');
    } else {
      setMaterialValidated('success');
    }
  };

  const onDueDateChange = (value: string, date: Date | undefined) => {
    // Clear banner if present
    if (formValidated != 'default') setFormValidated('default');

    setDueDateString(value);
    if (date == undefined) {
      setDueDateValidated('error');
      setDueDateHelperText('');
      return;
    }
    const dueDateTimeValue = date.valueOf() + 1000 * dueTime;
    setDueDate(new Date(dueDateTimeValue));
    if (dueDateTimeValue < Date.now()) {
      setDueDateValidated('warning');
      setDueDateHelperText('Due date and time cannot be before current time');
    } else {
      setDueDateValidated('success');
      setDueDateHelperText('');
    }
  };

  // const onDueTimeChange = (
  //   value: string,
  //   _event,
  //   hour?: number | undefined,
  //   minute?: number | undefined,
  //   period?: 'AM' | 'PM' | undefined,
  //   isValid?: boolean | undefined
  // ) => {
  //   // Clear banner if present
  //   if (formValidated != 'default') setFormValidated('default');

  //   setDueTimeString(value);

  //   if (isValid) {
  //     if (hour != undefined && minute != undefined) {
  //       let dueTime = 0;
  //       if (hour == 12) {
  //         // 12:00 AM == 0
  //         if (period == 'AM') dueTime += minute * 60;
  //         if (period == 'PM') dueTime += hour * 3600 + minute * 60

  //       } else if (period == 'PM') {
  //         // Afternoon
  //         dueTime += (hour + 12) * 3600 + minute * 60;
  //       } else {
  //         // Morning
  //         dueTime += hour * 3600 + minute * 60;
  //       }

  //       // const dueTime = hour * 3600 + minute * 60;
  //       setDueTime(dueTime);
  //       console.log(dueTime);
  //       const dueDateValue = new Date(Date.parse(dueDate));
  //       if (!Number.isNaN(dueDateValue)) {
  //         if (dueDateValue.valueOf() + 60000 * dueDateValue.getTimezoneOffset() + 1000 * dueTime < Date.now()) {
  //           setDueDateValidated('warning');
  //           setDueDateHelperText('Due date and time cannot be before current time');
  //           return;
  //         }
  //       }
  //       setDueDateValidated('success');
  //       setDueDateHelperText('');
  //     } else {
  //       setDueDateValidated('error');
  //       setDueDateHelperText('');
  //     }
  //   } else {
  //     setDueDateValidated('error');
  //     setDueDateHelperText('');
  //   }
  // };

  const onReleaseDateChange = (value: string, date: Date | undefined) => {
    console.log('onReleaseDateChange', date);
    // Clear banner if present
    if (formValidated != 'default') setFormValidated('default');

    setReleaseDateString(value);
    if (date == undefined) {
      setReleaseDateValidated('error');
      setReleaseDateHelperText('');
      return;
    }
    const releaseDateTimeValue = date.valueOf() + 1000 * releaseTime;
    setReleaseDate(new Date(releaseDateTimeValue));
    if (!Number.isNaN(releaseDateTimeValue)) {
      if (date.valueOf() + releaseTime > releaseDateTimeValue) {
        setReleaseDateValidated('warning');
        setReleaseDateHelperText('Release date and time cannot be after due date');
        return;
      }
    }
    setReleaseDateValidated('success');
    setReleaseDateHelperText('');
  };

  // const onReleaseTimeChange = (
  //   value: string,
  //   _event,
  //   hour?: number | undefined,
  //   minute?: number | undefined,
  //   period?: 'AM' | 'PM' | undefined,
  //   isValid?: boolean | undefined,
  // ) => {
  //   // Clear banner if present
  //   if (formValidated != 'default') setFormValidated('default');

  //   setReleaseTimeString(value)

  //   if (isValid) {
  //     if (hour != undefined && minute != undefined) {
  //       let releaseTime = 0;
  //       if (hour == 12) {
  //         // 12:00 AM == 0
  //         if (period == 'AM') releaseTime += minute * 60;
  //         if (period == 'PM') releaseTime += hour * 3600 + minute * 60

  //       } else if (period == 'PM') {
  //         // Afternoon
  //         releaseTime += (hour + 12) * 3600 + minute * 60;
  //       } else {
  //         // Morning
  //         releaseTime += hour * 3600 + minute * 60;
  //       }
  //       setReleaseTime(releaseTime);
  //       const dueDateTimeValue =
  //         Date.parse(dueDate) + new Date(Date.parse(dueDate)).getTimezoneOffset() + 1000 * releaseTime;
  //       const releaseDateTimeValue =
  //         Date.parse(releaseDate) + new Date(Date.parse(releaseDate)).getTimezoneOffset() + 1000 * releaseTime;
  //       if (!Number.isNaN(dueDateTimeValue)) {
  //         if (dueDateTimeValue < releaseDateTimeValue) {
  //           setReleaseDateValidated('warning');
  //           setReleaseDateHelperText('Release date and time cannot be before due date and time');
  //           return;
  //         }
  //       }
  //       setReleaseDateValidated('success');
  //       setReleaseDateHelperText('');
  //     } else {
  //       setReleaseDateValidated('error');
  //       setReleaseDateHelperText('');
  //     }
  //   } else {
  //     setReleaseDateValidated('error');
  //     setReleaseDateHelperText('');
  //   }
  // };

  const handleVisibility = (checked: boolean, event: React.FormEvent<HTMLInputElement> | undefined) => {
    // Clear banner if present
    if (formValidated != 'default') setFormValidated('default');

    if (!event) {
      if (visibility == '') {
        setVisibilityValidated('error');
      }
    } else {
      switch (event.currentTarget.id) {
        case 'visibility-radio-1':
          setVisibility('all');
          setVisibilityValidated('success');
          break;
        case 'visibility-radio-2':
          setVisibility('prof');
          setVisibilityValidated('success');
          break;
        default:
          setVisibility('');
          setVisibilityValidated('error');
          break;
      }
    }
  };

  const handleSubmit = async (_event) => {
    // Clear banner if present
    if (formValidated != 'default') setFormValidated('default');

    // Check for validity before proceeding
    if (
      nameValidated != 'success' ||
      materialValidated != 'success' ||
      dueDateValidated != 'success' ||
      releaseDateValidated != 'success' ||
      visibility == ''
    ) {
      console.log(nameValidated, materialValidated, dueDateValidated, releaseDateValidated, visibility);
      handleAssignmentName(assignmentName);
      onSelect(selected);
      onDueDateChange(dueDateString, dueDate);
      onReleaseDateChange(releaseDateString, releaseDate);
      handleVisibility(true, undefined);
      setFormValidated('error');
      return;
    } else {
      setFormValidated('success');
    }

    // Use some regex to prevent injection, probably
    const selectedMat = materials.get(selected);

    const dueDateTimestamp = Timestamp.fromDate(new Date(dueDate.valueOf()));

    const releaseDateTimestamp = Timestamp.fromDate(new Date(releaseDate.valueOf()));

    // Update Assignment
    await client
      .updateAssignment({
        assignmentId: assignmentId,
        classId: classId,
        displayName: assignmentName,
        material: selectedMat,
        isVisibleToAll: visibility === 'all',
        dueDate: dueDateTimestamp,
        releaseDate: releaseDateTimestamp,
        submissions: assignment?.submissions,
      })
      .then(() => {
        // history.back()
      })
      .catch((error) => {
        setError(error);

        // Use warning to set an error banner
        setFormValidated('warning');
      });
  };

  return (
    <React.Fragment>
      <PageSection>
        <Breadcrumb>
          <BreadcrumbItem to="/edu/teaching">Manage Classes</BreadcrumbItem>
          <BreadcrumbItem to={`/edu/class/${classId}`}>Class "{classId}"</BreadcrumbItem>
          <BreadcrumbItem to={`/edu/class/${classId}/assignments`}>Manage "{classId}" Assignments</BreadcrumbItem>
          <BreadcrumbItem isActive>Update Assignment "{assignment?.displayName}"</BreadcrumbItem>
        </Breadcrumb>
        <Modal
          title="Download Progress"
          variant={ModalVariant.small}
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
        >
          You can safely close this modal. The download will continue in the background.
          <Progress value={downloadProgress} measureLocation={ProgressMeasureLocation.inside} />
        </Modal>
        <Split hasGutter>
          <SplitItem>
            <Title headingLevel="h1">Update assignment "{assignment?.displayName}"</Title>
          </SplitItem>
          <SplitItem isFilled />
          <SplitItem>
            <SubmissionFilesDownload
              classId={classId}
              assignmentId={assignmentId}
              downloadProgress={(progress) => {
                // Rounds to 2 decimal places
                setDownloadProgress(Math.round(progress * 100) / 100);

                // Just in case its not exactly 100 due to rounding error
                // Would take a single file with 10,000 chunks or 100 files with 100 chunks each to trigger this prematurely
                if (progress >= 99.99) {
                  setIsModalOpen(false);
                  setDownloadProgress(0);
                }
              }}
              onClick={() => {
                setIsModalOpen(true);
              }}
            />
          </SplitItem>
        </Split>
        <Form>
          {formValidated === 'error' && (
            <FormAlert>
              <Alert
                variant="danger"
                title="Fill out all required fields before continuing."
                aria-live="polite"
                isInline
              />
            </FormAlert>
          )}
          {formValidated === 'warning' && (
            <FormAlert>
              <Alert variant="danger" title="Error updating assignment." aria-live="polite" isInline />
            </FormAlert>
          )}
          {formValidated === 'success' && (
            <FormAlert>
              <Alert variant="success" title="Successfully updated assignment." aria-live="polite" isInline />
            </FormAlert>
          )}
          <FormGroup
            label="Assignment Name"
            isRequired
            fieldId="add-assignment-assignment-name"
            helperTextInvalid={'Assignment name cannot be empty'}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            validated={nameValidated}
          >
            <TextInput
              validated={nameValidated}
              isRequired
              type="text"
              id="add-assignment-assignment-name-input"
              name="add-assignment-assignment-name-input"
              value={assignmentName}
              onChange={handleAssignmentName}
            />
          </FormGroup>
          <FormGroup
            label="Materials Ready to Assign"
            isRequired
            fieldId="add-assignment-materials-ready-to-assign"
            helperTextInvalid={'Please select a material'}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            validated={materialValidated}
          >
            <FormSelect
              validated={materialValidated}
              onChange={onSelect}
              aria-label="FormSelect Input"
              value={selected}
            >
              {dropdownItems}
            </FormSelect>
          </FormGroup>
          <FormGroup
            label="Due Date"
            isRequired
            fieldId="add-assignment-due-date-picker"
            helperText={dueDateHelperText}
            helperTextInvalid={'Please select a valid due date and time'}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            validated={dueDateValidated}
          >
            <DatePicker value={dueDateString} onChange={onDueDateChange} />
            {/* <TimePicker id="add-assignment-due-time-picker" onChange={onDueTimeChange} value={dueTimeString} /> */}
          </FormGroup>
          <FormGroup
            label="Release Date"
            isRequired
            fieldId="add-assignment-release-date-picker"
            helperText={releaseDateHelperText}
            helperTextInvalid={'Please select a valid release date and time'}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            validated={releaseDateValidated}
          >
            <DatePicker value={releaseDateString} onChange={onReleaseDateChange} />
            {/* <TimePicker id="add-assignment-release-time-picker" onChange={onReleaseTimeChange} value={releaseTimeString} /> */}
          </FormGroup>
          <FormGroup
            label="Visibility"
            isRequired
            role="radiogroup"
            isInline
            fieldId="visibility-radio-group"
            helperTextInvalid={'Select visibility'}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            validated={visibilityValidated}
          >
            <Radio
              name="basic-inline-radio"
              onChange={handleVisibility}
              isChecked={visibility === 'all'}
              label="All"
              id="visibility-radio-1"
            />
            <Radio
              name="basic-inline-radio"
              onChange={handleVisibility}
              isChecked={visibility === 'prof'}
              label="Professors/TAs Only"
              id="visibility-radio-2"
            />
          </FormGroup>
          <ActionGroup>
            <Button variant="primary" onClick={handleSubmit}>
              Save
            </Button>
            <Button
              variant="link"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </ActionGroup>
        </Form>
      </PageSection>
    </React.Fragment>
  );
};

export { AssignmentPage };
