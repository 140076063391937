import * as React from 'react';
import { EduService } from '@buf/sphere_edu.connectrpc_es/edu/v1/edu_connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { Code, ConnectError, createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { GenConfType, GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { Class, UserType } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_types_pb';
import { Member, Member_Role, Organization } from '@mergetb/api/portal/v1/workspace_types';
import Mutex from './FileUploadMutex';

/**
 * Used to prevent concurrent modifications when calling AddUserToClass in rapid succession
 */
const addUserMutex = new Mutex();

/**
 * Synchronizes the given Merge Organization to the respective Edu Class, creating the class if it does not exist
 * 
 * Assumes that the given organization is of category Class (Organization.category === "Class") and does not check
 * 
 * Will not create a Merge Organization to match an Edu Class, only created Edu Classes
 * 
 * @param classId Organization.name 
 * @param conf this thing -> `const conf = React.useContext(GeneralSettingsContext)`
 * @returns `true` if the sync was successful, `false` otherwise
 */
export const syncClass = async (classId: string, conf: GenConfType): Promise<boolean> => {
  // Passed conf through because I cannot figure out what the hell type 'client' is
  const transport = createConnectTransport({
    baseUrl: `${conf.eduApi}`,
    credentials: 'include',
  });
  const client = createPromiseClient(EduService, transport);

  // Check for existence of the class
  const data = await client.getClass({
    classId: classId,
  }).then(res => {
    if (res.class) {
      // Class exists
      return res.class;
    } else {
      // Class doesn't exist? Kind of undefined behavior if it gets here
      return undefined;
    }
  }).catch((err: ConnectError) => {
    if (err.code == Code.NotFound) {
      // Class doesn't exist
      return undefined;
    } else {
      // Actual error
      console.error(`Error checking for class ${classId}:`, err);
      return err;
    }
  });

  if (data == undefined) {
    const isSuccessful = await client.createClass({
      classId: classId,
    }).then(res => {
      // Check to make sure class was created
      return res.class !== undefined;
    }).catch((err: ConnectError) => {
      // Error making class :(
      console.error(`Error creating class ${classId}:`, err);
      return false;
    });

    // Let caller know if sync was successful or not
    return isSuccessful;
  } else if ((data as Class).classId !== undefined) { // Checks to see if data is of type Class
    // Class exists, so technically sync was successful
    return true;
  } else { // Only thing left is data being a ConnectError
    // Error
    return false;
  }
}

/**
 * Synchronizes the users and usertypes of all the users in the given Merge Organization to the respective Edu Class
 * Creates Edu Users if there is an existing Merge User but no corresponding Edu User
 * 
 * Assumes that the edu class exists (will return false if it doesn't)
 * 
 * Will not create Merge Users to match Edu Users, only creates Edu Users
 * 
 * Throws Error | ConnectError, please surround in trycatch block
 * 
 * @param org Merge Organization
 * @param conf this thing -> `const conf = React.useContext(GeneralSettingsContext)`
 * @returns `true` if the sync was successful, `false` otherwise
 */
export const syncUsers = async (org: Organization, conf: GenConfType): Promise<void> => {
  const transport = createConnectTransport({
    baseUrl: `${conf.eduApi}`,
    credentials: 'include',
  });
  const client = createPromiseClient(EduService, transport);

  const mergeUsers = org.members;

  // Get the class
  const eduClass = await client.getClass({
    classId: org.name,
  }).then(res => {
    if (res.class) return res.class;
    else return false;
  }).catch((err: ConnectError) => {
    throw err;
  });

  // Some kind of error getting the class
  if (typeof eduClass === "boolean") {
    throw new Error(`Error in getting edu class ${org.name}`);
  }

  const eduUsers = eduClass.users;

  // Wait for full execution before saying that the sync is successful
  await Promise.all(
    Object.keys(mergeUsers).map(async (mu, i) => {
      if (eduUsers[mu] == undefined) { // User doesn't exist in this class or just doesn't exist in Edu
        // Check if user exists
        const eduUser = await client.getUser({
          userId: mu,
        }).then(res => {
          if (res.user) return res.user;
          else return undefined; // Unexpected behavior
        }).catch((err: ConnectError) => {
          if (err.code == Code.NotFound) {
            console.log("GetUser:", mu, "doesn't exist");
            return false;
          } // Expected error, so no need to clutter the console
          else {
            throw err;
          }
        });

        if (eduUser == undefined) {
          // Unexpected behavior, exit function, sync failed, etc
          throw new Error(`Error in syncing users for ${org.name}: Error in getting edu user ${mu}`);
        } else if (typeof eduUser === "boolean") {
          // Create new edu user
          const createBody = {
            [org.name]: mergeUsers[mu].role == Member_Role.Creator
              ? UserType.PROFESSOR
              : mergeUsers[mu].role == Member_Role.Maintainer
                ? UserType.TA
                : UserType.STUDENT,
          }

          const isCreateSuccess = await client.createUser({
            userId: mu,
            fullName: "",
            classes: createBody,
            email: "",
            password: "",
          }).then(res => {
            if (res.user) return true;
            return false;
          }).catch((err: ConnectError) => {
            if (err.code == Code.AlreadyExists) return false;
            throw err;
          });

          if (!isCreateSuccess) {
            throw new Error(`Error creating edu user ${mu}`);
          }
        }
        // User exists, continue with adding to the class

        // Sort out perms for edu user based on merge user
        const addBody: { [key: string]: UserType } = {
          [mu]: mergeUsers[mu].role == Member_Role.Creator
            ? UserType.PROFESSOR
            : mergeUsers[mu].role == Member_Role.Maintainer
              ? UserType.TA
              : UserType.STUDENT,
        }

        // Use mutexes to call this function one at a time, since concurrent modifications may occur otherwise
        const release = await addUserMutex.acquire();

        // Add them to the class
        await client.addUsersToClass({
          classId: org.name,
          users: addBody,
        }).catch((err: ConnectError) => {
          console.error(mu, org.name, err);
          // throw err;
        }).finally(() => {
          // Release the mutex for the next caller to use
          release();
        });

      } else { // User does exist in this class, however we should check that the data is accurate
        // Check user's perm levels, in case they got promoted/demoted
        const mergePerm = mergeUsers[mu];
        const eduPerm = eduUsers[mu];

        let isCorrectPerm = false;
        let correctPerm = UserType.UNSPECIFIED;

        switch (mergePerm.role) {
          case Member_Role.Creator:
            isCorrectPerm = eduPerm === UserType.PROFESSOR;
            break;
          case Member_Role.Maintainer:
            isCorrectPerm = eduPerm === UserType.TA;
            break;
          case Member_Role.Member:
            isCorrectPerm = eduPerm === UserType.STUDENT;
            break;
          default:
            throw new Error(`Error in parsing merge user ${mu}'s membership in ${org.name}`);
        }

        if (!isCorrectPerm) {
          // Update perms
          const updatedUser = {
            [mu]: correctPerm,
          }

          // Update user in class
          await client.removeUsersFromClass({
            classId: org.name,
            userIds: [mu],
          }).catch((err: ConnectError) => {
            throw err;
          });

          await client.addUsersToClass({
            classId: org.name,
            users: updatedUser,
          }).catch((err: ConnectError) => {
            throw err;
          });
        }
      }

      // If we made it this far, then the user has been properly synced between merge and edu
      console.log(`Synced user ${mu} in ${org.name}`);
    })
  );
}