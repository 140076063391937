import {
  ActionGroup,
  Button,
  Form,
  FormGroup,
  PageSection,
  Radio,
  Title,
  Breadcrumb,
  BreadcrumbItem,
  TextInput,
  Alert,
  Bullseye,
  Spinner,
  Dropdown,
  DropdownItem,
  DropdownSeparator,
  DropdownToggle,
  FormSelect,
  FormSelectOption,
  DatePicker,
  FormAlert,
  Modal,
  ModalVariant,
  TimePicker,
} from '@patternfly/react-core';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Class, UserType, Material } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_types_pb';
import { GetClassResponse, GetMaterialsResponse } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { AuthContext } from '@app/lib/AuthProvider';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { ExclamationCircleIcon } from '@patternfly/react-icons';
import { createConnectTransport } from '@connectrpc/connect-web';
import { createPromiseClient } from '@connectrpc/connect';
import { EduService } from '@buf/sphere_edu.connectrpc_es/edu/v1/edu_connect';

type AddAssignmentProps = {
  classId: string;
};

type ValidatedProps = 'default' | 'success' | 'warning' | 'error';

const AddAssignments: React.FunctionComponent<AddAssignmentProps> = () => {
  const { classId } = useParams<AddAssignmentProps>();
  const { user } = React.useContext(AuthContext);
  const history = useHistory();

  const conf = React.useContext(GeneralSettingsContext);
  const username = user?.username;
  const transport = createConnectTransport({
    baseUrl: `${conf.eduApi}`,
    credentials: 'include',
  });
  const client = createPromiseClient(EduService, transport);

  const [response, setResponse] = React.useState<boolean>(false);

  const [dueDate, setDueDate] = React.useState<string>('');
  const [dueTime, setDueTime] = React.useState<number>(86399); // Hardcoded to 11:59:59 PM
  const [selected, setSelected] = React.useState('default');
  const [classroom, setClassroom] = React.useState<Class>();
  const [materials, setMaterials] = React.useState<Map<string, Material>>(new Map());
  const [visibility, setVisibility] = React.useState<string>('');
  const [releaseDate, setReleaseDate] = React.useState<string>('');
  const [releaseTime, setReleaseTime] = React.useState<number>(0); // Hardcoded to 12:00:00 AM
  const [dropdownItems, setDropdownItems] = React.useState<JSX.Element[]>([]);
  const [assignmentName, setAssignmentName] = React.useState<string>('');

  const [formValidated, setFormValidated] = React.useState<ValidatedProps>('default');
  const [nameValidated, setNameValidated] = React.useState<ValidatedProps>('default');
  const [materialValidated, setMaterialValidated] = React.useState<ValidatedProps>('default');
  const [dueDateValidated, setDueDateValidated] = React.useState<ValidatedProps>('default');
  const [releaseDateValidated, setReleaseDateValidated] = React.useState<ValidatedProps>('default');
  const [visibilityValidated, setVisibilityValidated] = React.useState<ValidatedProps>('default');
  const [dueDateHelperText, setDueDateHelperText] = React.useState<string>('');
  const [releaseDateHelperText, setReleaseDateHelperText] = React.useState<string>('');

  React.useMemo(async () => {
    const data = await client
      .getClass({
        classId: classId,
      })
      .then(async (response) => {
        setResponse(true);

        const data = await client
          .getMaterials({
            classId: classId,
          })
          .then((response) => response.materials);

        data.map((m) => {
          materials.set(m.materialId, m);
          setMaterials(materials);
        });

        dropdownItems.push(
          <FormSelectOption key={'default-option'} value={'default'} label={'Select Material to Assign'} />
        );
        setDropdownItems(dropdownItems);

        materials.forEach((m, k) => {
          console.log(k);
          dropdownItems.push(<FormSelectOption key={k} value={k} label={m.displayName} />);
          setDropdownItems(dropdownItems);
        });

        return response.class;
      })
      .then((classroom) => {
        setClassroom(classroom);
      });
  }, [classId]);

  if (classroom == undefined) {
    if (response) {
      return (
        <React.Fragment>
          <PageSection>
            <Alert variant="danger" title="Not Found Error">
              <pre>This class does not exist</pre>
            </Alert>
          </PageSection>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Bullseye>
            <Spinner size="xl" />
          </Bullseye>
        </React.Fragment>
      );
    }
  } else if (
    classroom.users[username] == undefined ||
    classroom.users[username] == UserType.STUDENT ||
    classroom.users[username] == UserType.UNSPECIFIED
  ) {
    console.log(classroom.users[username]);
    return (
      <React.Fragment>
        <PageSection>
          <Alert variant="danger" title="Permission Error">
            <pre>You do not have permission to access this content</pre>
          </Alert>
        </PageSection>
      </React.Fragment>
    );
  }

  const handleAssignmentName = (value: string) => {
    setAssignmentName(value);
    value = value.trim();
    if (value == '') {
      setNameValidated('error');
    } else {
      setNameValidated('success');
    }
  };

  const onSelect = (value: string) => {
    setSelected(value);
    if (value == 'default') {
      setMaterialValidated('error');
    } else {
      setMaterialValidated('success');
    }
  };

  const onDueDateChange = (value: string, date: Date | undefined) => {
    setDueDate(value);
    if (date == undefined) {
      setDueDateValidated('error');
      setDueDateHelperText('');
      return;
    }
    const dueDateTimeValue = date.valueOf() + 1000 * dueTime;
    if (dueDateTimeValue < Date.now()) {
      setDueDateValidated('warning');
      setDueDateHelperText('Due date and time cannot be before current time');
    } else {
      setDueDateValidated('success');
      setDueDateHelperText('');
    }
  };

  // const onDueTimeChange = (time: string, hour?: number | undefined, minute?: number | undefined, seconds?: number | undefined, isValid?: boolean | undefined) => {
  //   if (isValid) {
  //     if (hour != undefined && minute != undefined) {
  //       const dueTime = hour * 3600 + minute * 60;
  //       setDueTime(dueTime);
  //       const dueDateValue = new Date(Date.parse(dueDate))
  //       if (!Number.isNaN(dueDateValue)) {
  //         if (dueDateValue.valueOf() + 60000 * dueDateValue.getTimezoneOffset() + 1000 * dueTime < Date.now()) {
  //           setDueDateValidated('warning');
  //           setDueDateHelperText('Due date and time cannot be before current time');
  //           return;
  //         }
  //       }
  //       setDueDateValidated('success');
  //       setDueDateHelperText('');
  //     } else {
  //       setDueDateValidated('error');
  //       setDueDateHelperText('');
  //     }
  //   } else {
  //     setDueDateValidated('error');
  //     setDueDateHelperText('');
  //   }
  // }

  const onReleaseDateChange = (value: string, date: Date | undefined) => {
    setReleaseDate(value);
    if (date == undefined) {
      setReleaseDateValidated('error');
      setReleaseDateHelperText('');
      return;
    }
    const dueDateTimeValue = Date.parse(dueDate) + 1000 * dueTime;
    if (!Number.isNaN(dueDateTimeValue)) {
      if (date.valueOf() + releaseTime > dueDateTimeValue) {
        setReleaseDateValidated('warning');
        setReleaseDateHelperText('Release date and time cannot be after due date');
        return;
      }
    }
    setReleaseDateValidated('success');
    setReleaseDateHelperText('');
  };

  // const onReleaseTimeChange = (time: string, hour?: number | undefined, minute?: number | undefined, seconds?: number | undefined, isValid?: boolean | undefined) => {
  //   if (isValid) {
  //     if (hour != undefined && minute != undefined) {
  //       const releaseTime = hour * 3600 + minute * 60;
  //       setReleaseTime(releaseTime);
  //       const dueDateTimeValue = Date.parse(dueDate) + new Date(Date.parse(dueDate)).getTimezoneOffset() + 1000 * dueTime;
  //       const releaseDateTimeValue = Date.parse(releaseDate) + new Date(Date.parse(releaseDate)).getTimezoneOffset() + 1000 * releaseTime;
  //       if (!Number.isNaN(dueDateTimeValue)) {
  //         if (dueDateTimeValue < releaseDateTimeValue) {
  //           setReleaseDateValidated('warning');
  //           setReleaseDateHelperText('Release date and time cannot be before due date and time');
  //           return;
  //         }
  //       }
  //       setReleaseDateValidated('success');
  //       setReleaseDateHelperText('');
  //     } else {
  //       setReleaseDateValidated('error');
  //       setReleaseDateHelperText('');
  //     }
  //   } else {
  //     setReleaseDateValidated('error');
  //     setReleaseDateHelperText('');
  //   }
  // }

  const handleVisibility = (checked: boolean, event: React.FormEvent<HTMLInputElement> | undefined) => {
    if (!event) {
      if (visibility == '') {
        setVisibilityValidated('error');
      }
    } else {
      switch (event.currentTarget.id) {
        case 'visibility-radio-1':
          setVisibility('all');
          setVisibilityValidated('success');
          break;
        case 'visibility-radio-2':
          setVisibility('prof');
          setVisibilityValidated('success');
          break;
        default:
          setVisibility('');
          setVisibilityValidated('error');
          break;
      }
    }
  };

  const handleSubmit = async (_event) => {
    // Check for validity before proceeding
    if (
      nameValidated != 'success' ||
      materialValidated != 'success' ||
      dueDateValidated != 'success' ||
      releaseDateValidated != 'success' ||
      visibility == ''
    ) {
      console.log(nameValidated, materialValidated, dueDateValidated, releaseDateValidated, visibility);
      handleAssignmentName(assignmentName);
      onSelect(selected);
      onDueDateChange(dueDate, Number.isNaN(Date.parse(dueDate)) ? undefined : new Date(Date.parse(dueDate)));
      onReleaseDateChange(
        releaseDate,
        Number.isNaN(Date.parse(releaseDate)) ? undefined : new Date(Date.parse(releaseDate))
      );
      handleVisibility(true, undefined);
      setFormValidated('error');
      return;
    } else {
      setFormValidated('success');
    }

    // Replace every nonalphanumeric character with -, then lowercase it
    const formattedTitle = assignmentName.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase();

    const assignmentId = `${classId}-assignment-${formattedTitle}`;

    const selectedMat = materials.get(selected);

    const dueDateDate = new Date(Date.parse(dueDate));

    const dueDateTimestamp = Timestamp.fromDate(
      new Date(dueDateDate.valueOf() + 60000 * dueDateDate.getTimezoneOffset() + 1000 * dueTime)
    );

    const releaseDateDate = new Date(Date.parse(releaseDate));

    const releaseDateTimestamp = Timestamp.fromDate(
      new Date(releaseDateDate.valueOf() + 60000 * releaseDateDate.getTimezoneOffset() + 1000 * releaseTime)
    );

    // Create Assignment
    client.createAssignment({
      assignmentId: assignmentId,
      displayName: assignmentName,
      classId: classId,
      material: selectedMat,
      dueDate: dueDateTimestamp,
      releaseDate: releaseDateTimestamp,
      isVisibleToAll: visibility === 'all',
    });

    // Clear everything
    setAssignmentName('');
    setSelected('');
    setDueDate('');
    setReleaseDate('');
    setVisibility('');

    // Go back to class page
    history.goBack();
  };

  return (
    <React.Fragment>
      <PageSection>
        <Breadcrumb>
          <BreadcrumbItem to="/edu/teaching">Manage Classes</BreadcrumbItem>
          <BreadcrumbItem to={`/edu/class/${classId}`}>Class {classId}</BreadcrumbItem>
          <BreadcrumbItem isActive>Add Materials to {classId}</BreadcrumbItem>
        </Breadcrumb>
        <Title headingLevel="h1">Add materials to {classId}</Title>
        <Form>
          {formValidated === 'error' && (
            <FormAlert>
              <Alert
                variant="danger"
                title="Fill out all required fields before continuing."
                aria-live="polite"
                isInline
              />
            </FormAlert>
          )}
          {formValidated === 'warning' && (
            <FormAlert>
              <Alert variant="danger" title="Assignment already exists." aria-live="polite" isInline />
            </FormAlert>
          )}
          {formValidated === 'success' && (
            <FormAlert>
              <Alert variant="success" title="Successfully added assignment." aria-live="polite" isInline />
            </FormAlert>
          )}
          <FormGroup
            label="Assignment Name"
            isRequired
            fieldId="add-assignment-assignment-name"
            helperTextInvalid={'Assignment name cannot be empty'}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            validated={nameValidated}
          >
            <TextInput
              validated={nameValidated}
              isRequired
              type="text"
              id="add-assignment-assignment-name-input"
              name="add-assignment-assignment-name-input"
              value={assignmentName}
              onChange={handleAssignmentName}
            />
          </FormGroup>
          <FormGroup
            label="Materials Ready to Assign"
            isRequired
            fieldId="add-assignment-materials-ready-to-assign"
            helperTextInvalid={'Please select a material'}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            validated={materialValidated}
          >
            <FormSelect
              validated={materialValidated}
              onChange={onSelect}
              aria-label="FormSelect Input"
              value={selected}
            >
              {dropdownItems}
            </FormSelect>
          </FormGroup>
          <FormGroup
            label="Due Date"
            isRequired
            fieldId="add-assignment-due-date-picker"
            helperText={dueDateHelperText}
            helperTextInvalid={'Please select a valid due date and time'}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            validated={dueDateValidated}
          >
            <DatePicker value={dueDate} onChange={onDueDateChange} />
            {/* <TimePicker onChange={onDueTimeChange} /> */}
          </FormGroup>
          <FormGroup
            label="Release Date"
            isRequired
            fieldId="add-assignment-release-date-picker"
            helperText={releaseDateHelperText}
            helperTextInvalid={'Please select a valid release date and time'}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            validated={releaseDateValidated}
          >
            <DatePicker value={releaseDate} onChange={onReleaseDateChange} />
            {/* <TimePicker onChange={onReleaseTimeChange} /> */}
          </FormGroup>
          <FormGroup
            label="Visibility"
            isRequired
            role="radiogroup"
            isInline
            fieldId="visibility-radio-group"
            helperTextInvalid={'Select visibility'}
            helperTextInvalidIcon={<ExclamationCircleIcon />}
            validated={visibilityValidated}
          >
            <Radio
              name="basic-inline-radio"
              onChange={handleVisibility}
              isChecked={visibility === 'all'}
              label="All"
              id="visibility-radio-1"
            />
            <Radio
              name="basic-inline-radio"
              onChange={handleVisibility}
              isChecked={visibility === 'prof'}
              label="Professors/TAs Only"
              id="visibility-radio-2"
            />
          </FormGroup>
          <ActionGroup>
            <Button variant="primary" onClick={handleSubmit}>
              Add
            </Button>
            <Button
              variant="link"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </ActionGroup>
        </Form>
      </PageSection>
    </React.Fragment>
  );
};

export { AddAssignments };
