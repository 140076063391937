import * as React from 'react';
import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { AuthContext } from '@app/lib/AuthProvider';
import { EduService } from '@buf/sphere_edu.connectrpc_es/edu/v1/edu_connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { useLocation, useParams } from 'react-router-dom';
import { createPromiseClient } from '@connectrpc/connect';
import { User, UserType } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_types_pb';
import {
  ActionGroup,
  Alert,
  Button,
  Form,
  FormAlert,
  FormGroup,
  Modal,
  ModalVariant,
  PageSection,
  Radio,
  TextInput,
  Title,
} from '@patternfly/react-core';

type UserProp = {
  userId: string;
  userTypeString: string;
};

type UserState = {
  identityTypeString: string;
};

const stringToUserType = (str: string): UserType => {
  switch (str) {
    case 'Student':
      return UserType.STUDENT;
    case 'Teaching Assistant':
      return UserType.TA;
    case 'Professor':
      return UserType.PROFESSOR;
    case 'Admin':
      return UserType.ADMIN;
    case 'Unspecified':
    default:
      return UserType.UNSPECIFIED;
  }
};

const UserPage: React.FunctionComponent<UserProp> = () => {
  const location = useLocation<UserState>();
  const { userId } = useParams<UserProp>();
  const conf = React.useContext(GeneralSettingsContext);

  const transport = createConnectTransport({
    baseUrl: `${conf.eduApi}`,
    credentials: 'include',
  });
  const client = createPromiseClient(EduService, transport);

  const { identityTypeString } = location.state;
  const identityType = stringToUserType(identityTypeString);
  const isEnabled = identityType == UserType.ADMIN || identityType == UserType.PROFESSOR;

  const [user, setUser] = React.useState<User>(new User());
  const [fullName, setFullName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  React.useMemo(async () => {
    await client
      .getUser({
        userId: userId,
      })
      .then((res) => {
        if (res.user) {
          const u = res.user;
          setUser(u);
          setFullName(u.fullName);
          setEmail(u.email);
          setPassword(u.password);
        }
      });
  }, [userId]);

  const handleFullName = (value: string, _event) => {
    setFullName(value);
  };

  const handleEmail = (value: string, _event) => {
    setEmail(value);
  };

  const handlePassword = (value: string, _event) => {
    setPassword(value);
  };

  const handleDelete = async (_event) => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleConfirmAction = async () => {
    await client.recycleUser({
      userId: userId,
    });
  };

  const handleSubmit = async () => {
    // Create Material
    await client.updateUser({
      ...user,
      userId: userId,
      fullName: fullName,
      email: email,
      password: password,
    });
  };

  return (
    <React.Fragment>
      <PageSection>
        <Title headingLevel="h1">{isEnabled ? 'Edit User Information' : 'View User Information'}</Title>
        <Modal
          variant={ModalVariant.small}
          title="Confirm Recycle?"
          isOpen={isModalOpen}
          onClose={handleModalClose}
          actions={[
            <Button key="confirm" variant="primary" onClick={handleConfirmAction}>
              Yes
            </Button>,
            <Button key="cancel" variant="link" onClick={handleModalClose}>
              No
            </Button>,
          ]}
        >
          {`Are you sure you want to recycle "${userId}"?`}
        </Modal>
        <Form>
          {/* {formValidated === 'error' && (
          <FormAlert>
            <Alert variant="danger" title="Fill out all required fields before continuing." aria-live="polite" isInline />
          </FormAlert>
        )}
        {formValidated === 'warning' && (
          <FormAlert>
            <Alert variant="danger" title="Material already exists." aria-live="polite" isInline />
          </FormAlert>
        )}
        {formValidated === 'success' && (
          <FormAlert>
            <Alert variant="success" title="Successfully added material." aria-live="polite" isInline />
          </FormAlert>
        )} */}
          <FormGroup label="Full Name" fieldId="manage-user-full-name">
            <TextInput
              type="text"
              id="manage-user-full-name"
              name="manage-user-full-name"
              value={fullName}
              onChange={handleFullName}
              isDisabled
            />
          </FormGroup>
          <FormGroup label="Email" fieldId="manage-user-email">
            <TextInput
              type="email"
              id="manage-user-email"
              name="manage-user-email"
              value={email}
              onChange={handleEmail}
              isDisabled
            />
          </FormGroup>
          <FormGroup label="Password" fieldId="manage-user-password">
            <TextInput
              type="email"
              id="manage-user-password"
              name="manage-user-password"
              value={password}
              onChange={handlePassword}
              isDisabled
            />
          </FormGroup>
          <ActionGroup>
            <Button isDisabled variant="primary" onClick={handleSubmit}>
              Update
            </Button>
            <Button isDisabled variant="link" onClick={() => history.back()}>
              Cancel
            </Button>
            <Button isDisabled variant="danger" onClick={handleDelete}>
              Recycle
            </Button>
          </ActionGroup>
        </Form>
      </PageSection>
    </React.Fragment>
  );
};

export { UserPage };
