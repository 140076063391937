import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import { AuthContext } from '@app/lib/AuthProvider';
import {
  User,
  GetUserResponse,
  Organization,
  GetOrganizationResponse,
  Member_Role,
} from '@mergetb/api/portal/v1/workspace_types';
import { Breadcrumb, BreadcrumbItem, PageSection, Stack, StackItem, Title } from '@patternfly/react-core';
import { Thead, Tr, Th, Tbody, Td, TableComposable } from '@patternfly/react-table';
import React from 'react';
import { Link } from 'react-router-dom';

interface ClassOrgProps {
  oid?: string;
  uid?: string;
  category?: string;
}

const ClassOrg: React.FunctionComponent<ClassOrgProps> = ({ oid = '', uid = '' }) => {
  const conf = React.useContext(GeneralSettingsContext);
  const [org, setOrg] = React.useState<Organization>();

  React.useMemo(async () => {
    const data = await fetch(`${conf.api}/organization/${oid}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      cache: 'no-cache',
    }).then((response) => response.json());
    if (Object.hasOwnProperty.call(data, 'organization')) {
      setOrg(GetOrganizationResponse.fromJSON(data).organization);
    }
  }, [oid]);

  const filterOrgs = (org: Organization | undefined) => {
    if (org == undefined || org.category.toLowerCase() != 'class') {
      return null;
    } else if (
      org.members[uid] == undefined ||
      org.members[uid].role == Member_Role.Member ||
      org.members[uid].role == Member_Role.UNRECOGNIZED
    ) {
      return null;
    }

    return <Link to={`/edu/class/${oid}`}>{org.name}</Link>;
  };

  return filterOrgs(org);
};

const Teaching: React.FunctionComponent = () => {
  const { user: authuser } = React.useContext(AuthContext);
  const username = authuser?.username;

  const conf = React.useContext(GeneralSettingsContext);
  const [user, setUser] = React.useState<User>();

  React.useMemo(async () => {
    if (username == undefined) {
      return;
    }
    const data = await fetch(`${conf.api}/user/${username}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      cache: 'no-cache',
    }).then((response) => response.json());

    if (Object.hasOwnProperty.call(data, 'user')) {
      setUser(GetUserResponse.fromJSON(data).user);
    }
  }, [username, conf.api]);

  return (
    <PageSection>
      <Stack hasGutter>
        <StackItem>
          <Title headingLevel="h1">Teaching</Title>
        </StackItem>
        <StackItem>
          <TableComposable aria-label="Class Table" variant={'compact'} borders={false}>
            <Thead>
              <Tr>
                <Th>Select Class to Manage</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.keys(user !== undefined ? user.organizations : {}).map((o, i) => (
                <Tr key={i}>
                  <Td key={i}>
                    <ClassOrg oid={o} uid={user?.username} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </TableComposable>
        </StackItem>
      </Stack>
    </PageSection>
  );
};

export { Teaching };
